import {
  ATTRIBUTE_CATEGORY_PATH,
  ATTRIBUTE_COATING_PATH,
  ATTRIBUTE_COLOR_PATH,
  ATTRIBUTE_COUNTRY_PATH,
  ATTRIBUTE_DIAMETER_PATH,
  ATTRIBUTE_FLOWER_TYPE_PATH,
  ATTRIBUTE_HEIGHT_PATH,
  ATTRIBUTE_LENGTH_PATH,
  ATTRIBUTE_MANUFACTURER_PATH,
  ATTRIBUTE_MATURITY_PATH,
  ATTRIBUTE_PACKING_PATH,
  ATTRIBUTE_PRICE_PATH,
  ATTRIBUTE_PRICE_RANGE_PATH,
  ATTRIBUTE_QUALITY_PATH,
  ATTRIBUTE_SHAPE_PATH,
  ATTRIBUTE_SIZE_PATH,
  ATTRIBUTE_VOLUME_PATH,
  ATTRIBUTE_WEIGHT_PATH,
  ATTRIBUTE_WIDTH_PATH,
  INSTOCK_PATH,
  PREORDER_PATH
} from '@page-layouts/catalog/FiltersBar/options/constants';
import {
  filterJsonToString,
  filtersToMap,
  filterStringToJson,
  toNonFiltersQuery
} from '@page-layouts/catalog/FiltersBar/utils';
import { IFilterProp, IFilterPropMap, IFiltersJSON } from '@types';

export const ORDERED_SEO_FILTERS = [
  INSTOCK_PATH,
  PREORDER_PATH,
  ATTRIBUTE_CATEGORY_PATH,
  ATTRIBUTE_COUNTRY_PATH,
  ATTRIBUTE_FLOWER_TYPE_PATH,
  ATTRIBUTE_COLOR_PATH,
  ATTRIBUTE_PRICE_PATH,
  ATTRIBUTE_PRICE_RANGE_PATH,
  ATTRIBUTE_SIZE_PATH,
  ATTRIBUTE_DIAMETER_PATH,
  ATTRIBUTE_HEIGHT_PATH,
  ATTRIBUTE_WIDTH_PATH,
  ATTRIBUTE_LENGTH_PATH,
  ATTRIBUTE_VOLUME_PATH,
  ATTRIBUTE_SHAPE_PATH,
  ATTRIBUTE_WEIGHT_PATH,
  ATTRIBUTE_COATING_PATH,
  ATTRIBUTE_MANUFACTURER_PATH,
  ATTRIBUTE_MATURITY_PATH,
  ATTRIBUTE_PACKING_PATH,
  ATTRIBUTE_QUALITY_PATH
];

class SearchEngineFriendlyUrlsService {
  public filterKeyValueDivider = '--';
  public filterValueDivider = '--';
  public maxParsedFilters = 3;
  public extractedFilterValues: string[] = [];
  public queryString = '';
  public baseUrl = '';

  private skipPages = ['search', 'sales'];
  private filtersRegexp = new RegExp(ORDERED_SEO_FILTERS.join(this.filterKeyValueDivider + '|'));

  private sortFilters(a: string, b: string): number {
    return ORDERED_SEO_FILTERS.indexOf(a) - ORDERED_SEO_FILTERS.indexOf(b);
  }

  /**
   * Example of Array<RegExpMatchArray>
    [
      [ 'diameter--', index: 0, input: 'diameter--14', groups: undefined ],
      [ 'height--', index: 0, input: 'height--40', groups: undefined ]
    ]
   */
  extractFilters(url: string): Array<RegExpMatchArray | null> {
    const baseUrlParts: Array<string> = [];
    let filterFound = false;
    const parsed = url
      .split('/')
      .map((p) => {
        const match = p.match(this.filtersRegexp);
        if (!match && !filterFound) {
          if (p) {
            baseUrlParts.push(p);
          }
        } else {
          filterFound = true;
        }
        return match;
      })
      .filter(Boolean);
    this.baseUrl = baseUrlParts.join('/');
    this.extractedFilterValues = parsed?.reduce((acc: Array<string>, i) => {
      const values = i && i.input ? i.input.split(this.filterKeyValueDivider).slice(1) : [];
      return [...acc, ...values.filter(Boolean)];
    }, []);
    return parsed;
  }

  transformToQueryString(filters: Array<RegExpMatchArray | null>): string {
    this.queryString = filters
      .map((filter) => {
        const f = filter && filter.input ? filter.input.split(this.filterKeyValueDivider) : undefined;
        if (!f || !(f[0] || f[1])) return undefined;

        return `${f[0]}=${f.slice(1).join(',')}`;
      })
      .filter(Boolean)
      .join('&');
    return this.queryString;
  }

  transformFiltersToUrl(filters: IFilterPropMap): string {
    if (!filters || typeof filters !== 'object') return '';

    return Object.keys(filters)
      .sort(this.sortFilters)
      .map((key) => `${key}${this.filterKeyValueDivider}${filters[key].join(this.filterValueDivider)}`)
      .join('/');
  }

  getUrl(newFilters: IFilterProp[], filterParams?: IFiltersJSON): string {
    const filterMap = filtersToMap(newFilters);

    let str = '';
    let baseUrl = `/${this.baseUrl}`;
    if (
      Array.isArray(newFilters) &&
      newFilters.length &&
      newFilters.length <= this.maxParsedFilters &&
      !this.skipPages.includes(this.baseUrl)
    ) {
      baseUrl += `/${this.transformFiltersToUrl(filterMap)}`;
    } else {
      str = filterJsonToString(filterMap);
    }

    const query = toNonFiltersQuery(filterParams, str);

    return `${baseUrl}${query ? '?' + query : ''}`;
  }

  getQueryFromUrl(path: string, query?: string): string {
    const extractedFilters = this.extractFilters(path || '');

    if (
      this.extractedFilterValues.length &&
      this.extractedFilterValues.length <= this.maxParsedFilters &&
      !this.skipPages.includes(this.baseUrl)
    ) {
      return this.transformToQueryString(extractedFilters);
    }
    return decodeURIComponent(query || '');
  }

  getRedirectSEOUrl(query: string, extractedFilters?: Array<RegExpMatchArray | null>): string | null {
    if (extractedFilters && !extractedFilters.length && query) {
      const jsonFilters = filterStringToJson(decodeURIComponent(query), undefined);
      const searchFilters = jsonFilters?.arrayRes || [];

      if (searchFilters.length && searchFilters.length <= this.maxParsedFilters) {
        const additionalQuery = toNonFiltersQuery(jsonFilters);

        const redirectUrl = `/${this.baseUrl}/${this.transformFiltersToUrl(filtersToMap(searchFilters))}${
          additionalQuery ? '?' + additionalQuery : ''
        }`;
        return redirectUrl;
      }
      return null;
    }
    return null;
  }
}

const SEFUrlsService = new SearchEngineFriendlyUrlsService();

export { SEFUrlsService };
