export const defaultLocale = 'uk';
export const defaultCurrency = 'UAH';
export const UAH = '₴';

export const catalogProductTypeShear = 'shear-flowers';
export const catalogProductTypeAccessories = 'accessories';
export const catalogProductTypePotted = 'potted-flowers';
export const catalogProductTypeStable = 'preserved-flowers';
export const catalogProductTypeCeramic = 'ceramics';

export const catalogProductTypeCodes: Record<string, string> = {
  [catalogProductTypeShear]: '000000002',
  [catalogProductTypePotted]: '000000003',
  [catalogProductTypeStable]: '000000005',
  [catalogProductTypeAccessories]: '000000001',
  [catalogProductTypeCeramic]: '000000007'
};

export const STATUS_LABELS = {
  preorder: 'Під замовлення',
  soldout: 'Немає в наявності',
  limited: 'Закінчується',
  instock: 'В наявності',
  default: 'Відсутній'
};
